import { useEffect, useRef, useState } from "react";
import { CustomLoader } from "../../../shared/components/custom-loader/custom-loader";
import { FormInput } from "../../../shared/components/form-component/form-input";
import { CUSTOM_VALIDATION } from "../../../shared/validation/validation";
import moment from "moment";
import { FormDropdown } from "../../../shared/components/form-component/form-dropdown";
import { HELPER } from "../../../shared/helper/helper";
import { SERVICES } from "../../../core/services/services";
// import { MainContext } from "../../../../App";
import { Toast } from "primereact/toast";

export function BulkSettlementFilter(props) {
  const toast = useRef(null);
  // const mainContext = useContext(MainContext);
  const [loading] = useState(false);
  const [currentIndex] = useState(0);
  const [transaction, setTransaction] = useState({
    cardAcceptorId: null,
    status: null,
    endDate: moment().format("YYYY-MM-DD"),
    startDate: moment().format("YYYY-MM-DD"),
    // startDate: moment().subtract(1, "days").format("YYYY-MM-DD"),
    reportStatus: "",
    bulkSettlementKey: "",
    settlementStatus: "",
  });
  const [transactionError, setTransactionError] = useState({
    cardAcceptorId: null,
    endDate: null,
    status: null,
    startDate: null,
    reportStatus: null,
    bulkSettlementKey: null,
    settlementStatus: null,
  });
  const [merchants, setMerchants] = useState([]);

  const status = [
    { desc: "PENDING", code: "PENDING" },
    { desc: "COMPLETED", code: "COMPLETED" },
    { desc: "STARTED", code: "STARTED" },
  ];
  const reportStatus = [
    { desc: "PENDING", code: "PENDING" },
    { desc: "COMPLETED", code: "COMPLETED" },
    { desc: "READY", code: "READY" },
  ];

  useEffect(() => {
    function getMerchants() {
      SERVICES.GET_ALL_MERCHANTS()
        .then((data) => {
          const result = data?.result;
          setMerchants(result);
        })
        .catch((error) => {
          toast.current.show({
            severity: "error",
            summary: "Error getting merchants!",
            detail: HELPER.PROCESS_ERROR(error, "TOAST"),
            life: 10000,
          });
        });
    }
    getMerchants();
  }, []);

  function validateForm(e, name, type, refineName, required) {
    let value = e?.target?.value;
    const isEmpty = CUSTOM_VALIDATION.IS_EMPTY(value);
    const isValidInput = !isEmpty
      ? CUSTOM_VALIDATION.BASIC_VALIDATION(value, type)
      : false;
    if (isValidInput) {
      setTransaction({ ...transaction, [name]: value });
      setTransactionError({ ...transactionError, [name]: null });
    } else {
      let errorMessage =
        required && isEmpty ? `${refineName} is required` : null;
      if (!isValidInput) {
        errorMessage = `${refineName} is invalid`;
      }
      setTransaction({ ...transaction, [name]: null });
      setTransactionError({
        ...transactionError,
        [name]: errorMessage,
      });
    }
  }

  function filterSearch() {
    let payload = {};

    for (const property in transaction) {
      if (transaction[property]) {
        payload[property] = transaction[property];
      }
    }

    return payload;
  }

  function cancelModal() {
    props.closeModal();
  }

  const cancelButton = () => {
    if (!loading) {
      return (
        <button onClick={cancelModal} className="secondary-button">
          Cancel
        </button>
      );
    } else {
      return <div />;
    }
  };

  function validateDropdown(e, name) {
    const value = e.target.value;
    if (value) {
      setTransactionError({ ...transactionError, [name]: "" });
      setTransaction({ ...transaction, [name]: value });
    } else {
      let errorMessage = "Select type";
      setTransactionError({ ...transactionError, [name]: errorMessage });
    }
  }

  const submitButton = () => {
    if (!loading) {
      return (
        <button
          onClick={() => props?.searchFunction(filterSearch())}
          className="primary-button"
        >
          Filter
        </button>
      );
    } else {
      return (
        <div className="pull-up-element-2">
          <CustomLoader loadingText="Submitting..." />
        </div>
      );
    }
  };

  const bulkSettlementSearchView = () => {
    if (currentIndex) {
      return (
        <div>
          <div className="success-message-btn-container">
            <button
              onClick={() => {
                props.closeModal(true);
              }}
              className="primary-button success-message-btn"
            >
              Close
            </button>
          </div>
        </div>
      );
    } else {
      return <div>{bulkSettlementSearchForm()}</div>;
    }
  };

  const bulkSettlementSearchForm = () => {
    return (
      <div>
        <div className="custom-modal-title p-text-left">Filter</div>
        <div className="custom-dialog-subtitle-container p-mb-5"></div>
        <div className="p-grid">
          <div className="p-col-12">
            <FormDropdown
              required={true}
              label="merchantName"
              field="cardAcceptorId"
              error={transactionError["cardAcceptorId"]}
              disabled={loading}
              value={transaction["cardAcceptorId"]}
              fn={validateDropdown}
              options={merchants}
              placeholder="Select a super agent"
              filter
              filterBy="merchantName"
            />
          </div>
          {/* <div className="p-col-12">
                        <FormInput value={transaction['cardAcceptorId']} required={false} field="cardAcceptorId" type="INPUT" error={transactionError['cardAcceptorId']} fn={validateForm} loading={loading}  placeholder="Card acceptor id"/>
                    </div> */}
          <div className="p-col-12">
            <FormDropdown
              required={true}
              label="code"
              field="settlementStatus"
              error={transactionError["settlementStatus"]}
              disabled={loading}
              value={transaction["settlementStatus"]}
              fn={validateDropdown}
              options={status}
              placeholder="Settlement status"
            />
          </div>
          <div className="p-col-12">
            <FormDropdown
              required={true}
              label="code"
              field="reportStatus"
              error={transactionError["reportStatus"]}
              disabled={loading}
              value={transaction["reportStatus"]}
              fn={validateDropdown}
              options={reportStatus}
              placeholder="Report generation status"
            />
          </div>
          <div className="p-col-12">
            <FormInput
              value={transaction["bulkSettlementKey"]}
              required={false}
              field="bulkSettlementKey"
              type="INPUT"
              error={transactionError["bulkSettlementKey"]}
              fn={validateForm}
              loading={loading}
              placeholder="Bulk settlement key"
            />
          </div>
          <div className="p-col-6">
            <FormInput
              inputType="date"
              value={transaction["startDate"]}
              required={false}
              field="startDate"
              type="INPUT"
              error={transactionError["startDate"]}
              fn={validateForm}
              loading={loading}
              placeholder="Start date"
            />
          </div>
          <div className="p-col-6">
            <FormInput
              inputType="date"
              value={transaction["endDate"]}
              required={false}
              field="endDate"
              type="INPUT"
              error={transactionError["endDate"]}
              fn={validateForm}
              loading={loading}
              placeholder="End date"
            />
          </div>
          <div className="p-col-12">
            <div className="p-mt-5">
              <div className="p-grid">
                <div className={loading ? "p-col-12" : "p-col-6"}>
                  {cancelButton()}
                </div>
                <div className={loading ? "p-col-12" : "p-col-6"}>
                  {submitButton()}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };

  return (
    <div>
      <div>
        <Toast ref={toast} />
      </div>
      <div className="p-pb-2">{bulkSettlementSearchView()}</div>
    </div>
  );
}
