import { useState } from "react";
import { CustomLoader } from "../../../shared/components/custom-loader/custom-loader";
import { FormInput } from "../../../shared/components/form-component/form-input";
import { CUSTOM_VALIDATION } from "../../../shared/validation/validation";
import moment from "moment";
import { FormDropdown } from "../../../shared/components/form-component/form-dropdown";
// import { HELPER } from "../../../shared/helper/helper";
// import { SERVICES } from "../../../core/services/services";

export function HolidaysFilter(props) {
  const [loading] = useState(false);
  const [currentIndex] = useState(0);
  const [publicHoliday, setPublicHoliday] = useState({
    name: "",
    recurring: null,
    endDate: moment().format("YYYY-MM-DD"),
    startDate: moment().subtract(30, "days").format("YYYY-MM-DD"),
  });
  const [publicHolidayError, setPublicHolidayError] = useState({
    name: null,
    recurring: null,
    endDate: null,
    startDate: null,
  });
  const recurringChoices = [
    { name: "Yes", value: true },
    { name: "No", value: false },
  ];

  function validateForm(e, name, type, refineName, required) {
    let value = e?.target?.value;
    const isEmpty = CUSTOM_VALIDATION.IS_EMPTY(value);
    const isValidInput = !isEmpty
      ? CUSTOM_VALIDATION.BASIC_VALIDATION(value, type)
      : false;
    if (isValidInput) {
      setPublicHoliday({ ...publicHoliday, [name]: value });
      setPublicHolidayError({ ...publicHolidayError, [name]: null });
    } else {
      let errorMessage =
        required && isEmpty ? `${refineName} is required` : null;
      if (!isValidInput) {
        errorMessage = `${refineName} is invalid`;
      }
      setPublicHoliday({ ...publicHoliday, [name]: null });
      setPublicHolidayError({ ...publicHolidayError, [name]: errorMessage });
    }
  }

  function filterSearch() {
    let payload = {};

    for (const property in publicHoliday) {
      if (property === "recurring" && publicHoliday[property] !== null) {
        payload[property] = publicHoliday[property];
      } else if (publicHoliday[property]) {
        payload[property] = publicHoliday[property];
      }
    }
    return payload;
  }

  function cancelModal() {
    props.closeModal();
  }

  const cancelButton = () => {
    if (!loading) {
      return (
        <button onClick={cancelModal} className="secondary-button">
          Cancel
        </button>
      );
    } else {
      return <div />;
    }
  };

  function validateDropdown(e, name) {
    const value = e.target.value;
    setPublicHolidayError({ ...publicHolidayError, [name]: "" });
    setPublicHoliday({ ...publicHoliday, [name]: value });
  }

  const submitButton = () => {
    if (!loading) {
      return (
        <button
          onClick={() => props?.searchFunction(filterSearch())}
          className="primary-button"
        >
          Filter
        </button>
      );
    } else {
      return (
        <div className="pull-up-element-2">
          <CustomLoader loadingText="Submitting..." />
        </div>
      );
    }
  };

  const holidaysFilterView = () => {
    if (currentIndex) {
      return (
        <div>
          <div className="success-message-btn-container">
            <button
              onClick={() => {
                props.closeModal(true);
              }}
              className="primary-button success-message-btn"
            >
              Close
            </button>
          </div>
        </div>
      );
    } else {
      return <div>{holidaysFilterFormView()}</div>;
    }
  };

  const holidaysFilterFormView = () => {
    return (
      <div>
        <div className="custom-modal-title p-text-left">Filter</div>
        <div className="custom-dialog-subtitle-container p-mb-5"></div>
        <div className="p-grid">
          <div className="p-col-12">
            <FormInput
              value={publicHoliday["name"]}
              required={false}
              field="name"
              type="INPUT"
              error={publicHolidayError["name"]}
              fn={validateForm}
              loading={loading}
              placeholder="Name"
            />
          </div>
          <div className="p-col-12">
            <FormDropdown
              required={true}
              label="name"
              field="recurring"
              error={publicHolidayError["recurring"]}
              disabled={loading}
              value={publicHoliday["recurring"]}
              fn={validateDropdown}
              options={recurringChoices}
              placeholder="Recurring?"
            />
          </div>
          <div className="p-col-6">
            <FormInput
              inputType="date"
              value={publicHoliday["startDate"]}
              required={false}
              field="startDate"
              type="INPUT"
              error={publicHolidayError["startDate"]}
              fn={validateForm}
              loading={loading}
              placeholder="Start date"
            />
          </div>
          <div className="p-col-6">
            <FormInput
              inputType="date"
              value={publicHoliday["endDate"]}
              required={false}
              field="endDate"
              type="INPUT"
              error={publicHolidayError["endDate"]}
              fn={validateForm}
              loading={loading}
              placeholder="End date"
            />
          </div>
          <div className="p-col-12">
            <div className="p-mt-5">
              <div className="p-grid">
                <div className={loading ? "p-col-12" : "p-col-6"}>
                  {cancelButton()}
                </div>
                <div className={loading ? "p-col-12" : "p-col-6"}>
                  {submitButton()}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };

  return <div className="p-pb-2">{holidaysFilterView()}</div>;
}
