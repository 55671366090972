import { useEffect, useMemo, useRef, useState } from "react";
import { CustomLoader } from "../../../../shared/components/custom-loader/custom-loader";
import { FormInput } from "../../../../shared/components/form-component/form-input";
import { CUSTOM_VALIDATION } from "../../../../shared/validation/validation";
import { HELPER } from "../../../../shared/helper/helper";
import { Toast } from "primereact/toast";
import { CustomToast } from "../../../../shared/components/alert/custom-toast";
import { FormDropdown } from "../../../../shared/components/form-component/form-dropdown";
import { Optional } from "../../../../shared/components/optional/optional";
import { ToolTip } from "../../../../shared/components/tooltip/tooltip";
import "./configurations.css";
import { BACKOFFICE_SERVICE } from "../../../../core/services/backoffice-service";
import { CustomMessage } from "../../../../shared/components/alert/custom-message";

export function EditReconConfiguration(props) {
  const toast = useRef(null);
  const [loading, setLoading] = useState(false);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [isInValidConfig, setIsInValidConfig] = useState(false);
  const [errorTitle, setErrorTitle] = useState(null);
  const [errorMessage, setErrorMessage] = useState(null);
  const [config, setConfig] = useState({
    fieldName: "",
    columnHeader: "",
    canConfigure: null,
    rule: null,
    valueFormat: "",
    valueType: "",
    ruleType: "",
  });
  const [configFormError, setConfigFormError] = useState({
    fieldName: null,
    columnHeader: null,
    canConfigure: null,
    rule: null,
    valueFormat: null,
    valueType: null,
    ruleType: null,
  });
  const configValueTypes = useMemo(
    () => ["TEXT", "RULE_BASED", "NUMBER", "DATE"],
    []
  );
  const ruleTypes = ["SUBSTRING", "SELECTION"];
  const dateFormats = [
    "dd/MM/yyyy",
    "MM/DD/YY",
    "DD/MM/YY",
    "YY/MM/DD",
    "Month D, Yr",
    "M/D/YY",
    "D/M/YY",
    "YY/M/D",
    "MMDDYY",
    "DDMMYY",
    "YYMMDD",
  ];
  // const dateFormats = [
  //   "dd/MM/yyyy",
  //   "MM/DD/YY (02/17/2022)",
  //   "DD/MM/YY (17/02/2022)",
  //   "YY/MM/DD (2022/02/17)",
  //   "Month D, Yr (February 17, 2022)",
  //   "M/D/YY (2/17/2022)",
  //   "D/M/YY (17/2/2009)",
  //   "YY/M/D (2009/2/17)",
  //   "MMDDYY (02172009)",
  //   "DDMMYY (17022009)",
  //   "YYMMDD (20090217)",
  // ];
  // const [ruleValues, setRuleValues] = useState([]);
  const [configRules, setConfigRules] = useState([]);
  const [ruleProperties, setRuleProperties] = useState([]);

  useEffect(() => {
    let config = { ...props.config };
    if (config?.rule?.ruleType) {
      config = {
        ...config,
        ruleType: config?.rule?.ruleType,
      };
    }
    delete config?.actions;
    delete config?.detailsFunction;
    setConfig({
      ...config,
    });
    if (props.config?.rule) {
      let rule = config?.rule?.rule;
      let ruleProperties = [];
      let arr = [];
      for (const property in rule) {
        ruleProperties.push(property);
        arr.push({ [property]: rule[property] });
        // ruleObj[property] = rule[property];
      }
      setRuleProperties(ruleProperties);
      setConfigRules(arr);
    }
    if (props.config?.valueType === "DATE") {
      configValueTypes.push("DATE");
    }
  }, [props.config, configValueTypes]);

  function validateForm(e, name, type, refineName, required) {
    let value = e?.target?.value;
    const isEmpty = CUSTOM_VALIDATION.IS_EMPTY(value);
    const isValidInput = !isEmpty
      ? CUSTOM_VALIDATION.BASIC_VALIDATION(value, type)
      : false;
    if (isValidInput) {
      setIsInValidConfig(false);
      setConfig({
        ...config,
        [name]: value,
      });
      setConfigFormError({ ...configFormError, [name]: null });
    } else {
      let errorMessage =
        required && isEmpty ? `${refineName} is required` : null;
      if (!isValidInput) {
        errorMessage = `${refineName} is invalid`;
      }
      setIsInValidConfig(true);
      setConfig({
        ...config,
        [name]: null,
      });
      setConfigFormError({ ...configFormError, [name]: errorMessage });
    }
  }

  function updateConfig() {
    let payload = {
      ...config,
    };
    if (config.valueType === "RULE_BASED") {
      let ruleObj = {};
      configRules.forEach((rule, index) => {
        ruleObj[ruleProperties[index]] = rule[ruleProperties[index]];
      });
      payload = {
        ...payload,
        rule: {
          ...payload.rule,
          rule: ruleObj,
        },
      };
    }
    setLoading(true);
    BACKOFFICE_SERVICE.UPDATE_RECON_CONFIGURATION(payload, props.configKey)
      .then((response) => {
        setErrorTitle(null);
        // props.closeModal(true);
        setCurrentIndex(1);
      })
      .catch((error) => {
        // console.log(error);
        setErrorTitle("Error");
        setErrorMessage(HELPER.PROCESS_ERROR(error));
        setLoading(false);
      });
  }

  function cancelModal() {
    props.closeModal();
  }

  const customCancelButton = () => {
    if (!loading) {
      return (
        <button onClick={cancelModal} className="secondary-button">
          Cancel
        </button>
      );
    } else {
      return <div />;
    }
  };

  const customSubmitButton = () => {
    if (!loading) {
      return (
        <button
          onClick={updateConfig}
          disabled={isInValidConfig}
          className="primary-button"
        >
          Update
        </button>
      );
    } else {
      return (
        <div className="pull-up-element-2">
          <CustomLoader loadingText="Submitting..." />
        </div>
      );
    }
  };

  function validateDropdown(e, name) {
    const value = e.target.value;
    setConfigFormError({ ...configFormError, [name]: "" });
    setConfig({ ...config, [name]: value });
  }

  function handleRuleChange(index, e) {
    let newFormValues = [...configRules];
    newFormValues[index][e.target.name] = e.target.value;
    setConfigRules(newFormValues);
    // setFormValues(newFormValues);
  }

  const configFormView = () => {
    if (currentIndex) {
      return (
        <div>
          <div>
            <CustomMessage messageType="success" />
          </div>
          <div>
            <p className="success-message-text">
              Configuration updated successfully!
            </p>
          </div>
          <div className="success-message-btn-container p-text-center">
            <p
              onClick={() => {
                props.closeModal(true);
              }}
              className="close-modal add-cursor"
            >
              Close
            </p>
          </div>
        </div>
      );
    } else {
      return <div>{configForm()}</div>;
    }
  };

  // const rules = () => {
  //   return configRules.map((rule, index) => (
  //     <tr key={index}>
  //       <td>{ruleProperties[index]}:</td>
  //       <td>
  //         <input
  //           type="text"
  //           name={ruleProperties[index]}
  //           value={rule[ruleProperties[index]]}
  //           onChange={(e) => handleRuleChange(index, e)}
  //         />
  //       </td>
  //     </tr>
  //   ));
  // };

  const configForm = () => {
    return (
      <div>
        <div className="custom-modal-title p-text-left">
          Update Configuration
        </div>
        <div className="custom-dialog-subtitle-container p-mb-5">
          <p className="custom-dialog-subtitle">* Required Fields</p>
        </div>
        <div>
          {errorTitle && (
            <CustomToast
              title={errorTitle}
              description={errorMessage}
              type="error"
            />
          )}
        </div>
        <div className="p-grid">
          <div className="p-col-12" style={{ marginBottom: "1rem" }}>
            <label>
              Config Format: <ToolTip tip="Config format..." />
            </label>
            <FormDropdown
              required={true}
              // label="name"
              field="valueType"
              error={configFormError["valueType"]}
              disabled={!config?.canConfigure}
              value={config["valueType"]}
              fn={validateDropdown}
              options={configValueTypes}
              placeholder="Value type"
            />
          </div>
          <div className="p-col-12">
            <table>
              <tbody>
                <tr>
                  <td>Field name</td>
                  <td>Spreadsheet column name</td>
                </tr>
                <tr style={{ padding: "2rem 0" }}>
                  <td
                    style={{
                      padding: `${
                        config?.valueType !== "TEXT"
                          ? "0 0 1rem 0.5rem"
                          : "0 0 0 0.5rem"
                      }`,
                    }}
                  >
                    {config.fieldName} :
                  </td>
                  <td
                    style={{
                      padding: `${
                        config?.valueType !== "TEXT"
                          ? "0 0 1rem 0.5rem"
                          : "0 0 0 0.5rem"
                      }`,
                    }}
                  >
                    <FormInput
                      value={config["columnHeader"]}
                      required={false}
                      field="columnHeader"
                      type="INPUT"
                      error={configFormError["columnHeader"]}
                      fn={validateForm}
                      loading={false}
                      placeholder=""
                      hasFloatingLabel={false}
                    />
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <Optional
            show={config?.rule?.ruleType && config?.valueType === "RULE_BASED"}
          >
            <div className="p-col-12">
              <label>
                Rule Type: <ToolTip tip="Rule type..." />
              </label>
              <FormDropdown
                required={true}
                field="ruleType"
                error={configFormError["ruleType"]}
                disabled={true}
                value={config["ruleType"]}
                fn={validateDropdown}
                options={ruleTypes}
                placeholder="Rule type"
              />
            </div>
          </Optional>
          <Optional
            show={
              config?.valueType === "RULE_BASED" || config?.valueType === "DATE"
            }
          >
            <div className="p-col-12">
              <table className="config-rules">
                <tbody>
                  <Optional show={config?.valueType === "RULE_BASED"}>
                    {configRules.map((rule, index) => {
                      return (
                        <tr key={index}>
                          <td>{ruleProperties[index]}:</td>
                          <td>
                            <input
                              type="text"
                              name={ruleProperties[index]}
                              value={rule[ruleProperties[index]]}
                              onChange={(e) => handleRuleChange(index, e)}
                            />
                          </td>
                        </tr>
                      );
                    })}
                  </Optional>
                  <Optional show={config?.valueType === "DATE"}>
                    <tr>
                      <td>Date Format:</td>
                      <td>
                        <FormDropdown
                          required={true}
                          // label="name"
                          field="valueFormat"
                          error={configFormError["valueFormat"]}
                          disabled={false}
                          value={config["valueFormat"]}
                          fn={validateDropdown}
                          options={dateFormats}
                          placeholder="Value format"
                        />
                      </td>
                    </tr>
                  </Optional>
                </tbody>
              </table>
            </div>
          </Optional>
          {/* <div
            style={{ display: "flex", padding: "0 0.5rem" }}
            className="p-mt-3"
          >
            <input
              type="checkbox"
              className="confirmation-checkbox"
              value={disputeAcceptanceConfirmation}
              onChange={handleDisputeAcceptanceConfirmation}
            />
            <small className="p-text-left p-ml-2">Is Mandatory</small>
          </div> */}
          <div className="p-col-12">
            <div className="p-mt-3">
              <div className="p-grid">
                <div className={loading ? "p-col-12" : "p-col-6"}>
                  {customCancelButton()}
                </div>
                <div className={loading ? "p-col-12" : "p-col-6"}>
                  {customSubmitButton()}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };

  return (
    <div className="recon-configs">
      <div>
        <Toast ref={toast} />
      </div>
      <div className="p-pb-2">{configFormView()}</div>
    </div>
  );
}
