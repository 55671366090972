import { useCallback, useEffect, useRef, useState } from "react";
import { BACKOFFICE_SERVICE } from "../../../core/services/backoffice-service";
import { SERVICES } from "../../../core/services/services";
import { CustomMessage } from "../../../shared/components/alert/custom-message";
import { CustomToast } from "../../../shared/components/alert/custom-toast";
import { CustomLoader } from "../../../shared/components/custom-loader/custom-loader";
import { FormDropdown } from "../../../shared/components/form-component/form-dropdown";
import { FormInput } from "../../../shared/components/form-component/form-input";
import { FormRadioInput } from "../../../shared/components/form-component/form-radio-input";
import { Optional } from "../../../shared/components/optional/optional";
import { BACKOFFICE_CONFIGS } from "../../../shared/constants";
import { HELPER } from "../../../shared/helper/helper";
import { CUSTOM_VALIDATION } from "../../../shared/validation/validation";
import { UserDetailsConfirmation } from "./user-details-confirmation";

export function CreateUser(props) {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [isValidForm, setIsValidForm] = useState(true);
  const [userRoles, setUserRoles] = useState([]);
  const [canCreateAdUsers, setCanCreateAdUsers] = useState(false);
  const [userType, setUserType] = useState("local-user");
  const [isVerifyingUsername, setIsVerifyingUsername] = useState(false);
  const verifyUsername = useRef(null);
  const [formError, setFormError] = useState(null);
  const [successMessage, setSuccessMessage] = useState(null);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [formIndex, setFormIndex] = useState(0);
  const [newUser, setNewUser] = useState({
    companyName: "",
    email: "",
    firstname: "",
    lastname: "",
    role: "",
    username: "",
  });
  const [userFormError, setUserFormError] = useState({
    companyName: null,
    email: null,
    firstname: null,
    lastname: null,
    role: null,
    username: null,
  });
  const [isValidAdUsername, setIsValidAdUsername] = useState(false);

  useEffect(() => {
    function validateForm() {
      let isValid;
      if (userType === "local-user") {
        const optionalFields = ["companyName", "username"];
        isValid = CUSTOM_VALIDATION.IS_VALID_FORM(newUser, optionalFields);
      } else {
        const optionalFields = ["companyName"];
        isValid =
          CUSTOM_VALIDATION.IS_VALID_FORM(newUser, optionalFields) &&
          isValidAdUsername;
      }
      setIsValidForm(
        isValid && !CUSTOM_VALIDATION.VALID_OBJ_ANY(userFormError)
      );
    }
    validateForm();
  }, [newUser, userFormError, userType, isValidAdUsername]);

  const getUserRoles = useCallback(() => {
    setLoading(true);
    BACKOFFICE_SERVICE.GET_USER_ROLES()
      .then((data) => {
        const result = data.result;
        let arr = [];
        result.forEach((e) => {
          arr.push({
            label: e,
            value: e,
          });
        });
        setUserRoles(arr);
      })
      .catch((error) => {
        setError(HELPER.PROCESS_ERROR(error));
        setLoading(false);
      });
  }, []);

  const getUserCreationConfiguration = useCallback(() => {
    setLoading(true);
    SERVICES.GET_SINGLE_CONFIGURATION(
      BACKOFFICE_CONFIGS.ACTIVE_DIRECTORY_STATUS
    )
      .then((data) => {
        const result = data?.result;
        let hasAdDirectory = result.configValue === "true" ? true : false;
        setCanCreateAdUsers(hasAdDirectory);
        setLoading(false);
      })
      .catch((error) => {
        setError(HELPER.PROCESS_ERROR(error));
        setLoading(false);
      });
  }, []);

  function handleUsernameValidation(username) {
    setIsValidAdUsername(false);
    setIsVerifyingUsername(true);
    if (verifyUsername.current) {
      clearTimeout(verifyUsername.current);
    }
    if (username.length) {
      verifyUsername.current = setTimeout(() => {
        getAdUserByUsername(username);
      }, 1000);
    } else {
      setIsVerifyingUsername(false);
      return;
    }
  }

  function getAdUserByUsername(username) {
    setIsVerifyingUsername(true);
    const payload = HELPER.TO_URL_STRING({
      username: username,
    });
    BACKOFFICE_SERVICE.GET_AD_USER_BY_USERNAME(payload)
      .then((data) => {
        const result = data?.result;
        // const result = mockAdUserPayload?.result;
        setNewUser({
          ...newUser,
          username: username,
          firstname: result?.firstname,
          lastname: result?.lastname,
          email: result?.email,
          companyName: result?.companyName,
        });
        setIsValidAdUsername(true);
        setIsVerifyingUsername(false);
      })
      .catch((error) => {
        setUserFormError({
          ...userFormError,
          username: HELPER.PROCESS_ERROR(error, "NAME_ENQUIRY"),
        });
        setIsVerifyingUsername(false);
      });
  }

  function previewNewUser() {
    // set username value to be same as email
    if (userType === "local-user") {
      setNewUser({ ...newUser, username: newUser.email });
    }
    setFormIndex(1);
  }

  function createUser() {
    setFormError(false);
    setIsSubmitting(true);
    let CREATE_USER_SERVICE;
    if (userType === "ad-user") {
      CREATE_USER_SERVICE = BACKOFFICE_SERVICE.CREATE_AD_USER;
    } else {
      CREATE_USER_SERVICE = BACKOFFICE_SERVICE.CREATE_LOCAL_USER;
    }
    CREATE_USER_SERVICE(newUser)
      .then((data) => {
        if (userType !== "ad-user") {
          setSuccessMessage(
            "New request for user creation has been received and awaits approval."
          );
        } else {
          setSuccessMessage("User created successfully.");
        }
        setCurrentIndex(1);
        setIsSubmitting(false);
      })
      .catch((error) => {
        setFormError(HELPER.PROCESS_ERROR(error));
        setIsSubmitting(false);
      });
  }

  useEffect(() => {
    getUserCreationConfiguration();
    getUserRoles();
  }, [getUserRoles, getUserCreationConfiguration]);

  function handleUserTypeSelection(e) {
    const value = e?.target?.value;
    resetForm();
    setUserType(value);
  }

  function resetForm() {
    setUserFormError({
      companyName: null,
      email: null,
      firstname: null,
      lastname: null,
      role: null,
      username: null,
    });
    setNewUser({
      companyName: "",
      email: "",
      firstname: "",
      lastname: "",
      role: "",
      username: "",
    });
  }

  function validateDropdown(e, name) {
    const value = e.target.value;
    if (value) {
      setUserFormError({ ...userFormError, [name]: "" });
      setNewUser({ ...newUser, [name]: value });
    }
  }

  function validateForm(e, name, type, refineName, required) {
    let value = e?.target?.value;
    const isEmpty = CUSTOM_VALIDATION.IS_EMPTY(value);
    const isValidInput = !isEmpty
      ? CUSTOM_VALIDATION.BASIC_VALIDATION(value, type)
      : false;
    setNewUser({ ...newUser, [name]: value });
    if (name === "username" && userType === "ad-user") {
      handleUsernameValidation(value);
    }
    if (isValidInput) {
      setUserFormError({ ...userFormError, [name]: null });
    } else {
      let errorMessage =
        required && isEmpty ? `${refineName} is required` : null;
      if (!isValidInput) {
        errorMessage = `${refineName} is invalid`;
      }
      setNewUser({ ...newUser, [name]: null });
      setUserFormError({ ...userFormError, [name]: errorMessage });
    }
  }

  const formContent = () => {
    if (formIndex === 0) {
      return (
        <div className="p-grid">
          <div className="p-col-12">
            <FormDropdown
              required={true}
              label="label"
              field="role"
              error={userFormError["role"]}
              disabled={loading}
              value={newUser["role"]}
              fn={validateDropdown}
              options={userRoles}
              placeholder="Select a role *"
            />
          </div>
          <Optional show={userType === "ad-user"}>
            <div className="p-col-12">
              <FormInput
                verifyingField="Username"
                verifying={isVerifyingUsername}
                value={newUser["username"]}
                required={true}
                field="username"
                type="NAME"
                error={userFormError["username"]}
                fn={validateForm}
                loading={loading}
                placeholder="Username"
              />
            </div>
          </Optional>
          <div className="p-col-12">
            <FormInput
              value={newUser["firstname"]}
              required={true}
              field="firstname"
              type="NAME"
              error={userFormError["firstname"]}
              fn={validateForm}
              loading={loading}
              disabled={canCreateAdUsers && userType === "ad-user"}
              placeholder="First Name"
            />
          </div>
          <div className="p-col-12">
            <FormInput
              value={newUser["lastname"]}
              required={true}
              field="lastname"
              type="NAME"
              error={userFormError["lastname"]}
              fn={validateForm}
              loading={loading}
              disabled={canCreateAdUsers && userType === "ad-user"}
              placeholder="Last Name"
            />
          </div>
          <div className="p-col-12">
            <FormInput
              value={newUser["email"]}
              required={true}
              field="email"
              type="EMAIL"
              error={userFormError["email"]}
              fn={validateForm}
              loading={loading}
              placeholder="Email"
              disabled={canCreateAdUsers && userType === "ad-user"}
            />
          </div>
          <div className="p-col-12">
            <FormInput
              value={newUser["companyName"]}
              required={false}
              field="companyName"
              type="NAME"
              error={userFormError["companyName"]}
              fn={validateForm}
              loading={loading}
              placeholder="Company Name"
              disabled={canCreateAdUsers && userType === "ad-user"}
            />
          </div>
        </div>
      );
    } else {
      return (
        <UserDetailsConfirmation
          userDetails={newUser}
          isLocalUser={userType === "local-user"}
        />
      );
    }
  };

  const createUserForm = () => {
    if (loading) {
      return (
        <div>
          <CustomLoader loadingText="Loading. Please wait..." />
        </div>
      );
    } else {
      return (
        <div>
          <div className="custom-modal-title p-text-left">
            {formIndex === 0
              ? "New user"
              : "Confirm New Back Office User Details"}
          </div>
          <div className="custom-dialog-subtitle-container p-mb-5">
            <p className="custom-dialog-subtitle">
              {formIndex === 0
                ? "Fill the form below to create user"
                : "Verify the information below"}
            </p>
            <Optional show={formIndex === 0}>
              <p className="custom-dialog-subtitle">* Required Fields</p>
            </Optional>
          </div>
          <Optional show={canCreateAdUsers && formIndex === 0}>
            <div className="flex default p-mb-4">
              <FormRadioInput
                id="local-user"
                value="local-user"
                name="user-type"
                checked={userType === "local-user"}
                handleChange={handleUserTypeSelection}
                label="Local User"
              />
              <div className="p-ml-3">
                <FormRadioInput
                  id="ad-user"
                  value="ad-user"
                  name="user-type"
                  checked={userType === "ad-user"}
                  handleChange={handleUserTypeSelection}
                  label="Active Directory User"
                />
              </div>
            </div>
          </Optional>
          <>{formContent()}</>
          <Optional show={formError}>
            <div className="p-col-12">
              <div className="p-pb-1">
                <CustomToast
                  title="Error"
                  description={formError}
                  type="error"
                />
              </div>
            </div>
          </Optional>
          <Optional show={!isSubmitting}>
            <div className="p-col-12">
              <div className="p-mt-5">
                <div className="p-grid">
                  <div className="p-col-6">
                    <button
                      onClick={
                        formIndex === 0
                          ? props.closeModal
                          : () => setFormIndex(0)
                      }
                      className="secondary-button"
                    >
                      {formIndex === 0 ? "Cancel" : "Back"}
                    </button>
                  </div>
                  <div className="p-col-6">
                    <button
                      disabled={!isValidForm}
                      onClick={formIndex === 0 ? previewNewUser : createUser}
                      className="primary-button"
                    >
                      {formIndex === 0 ? "Next" : "Submit"}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </Optional>
          <Optional show={isSubmitting}>
            <div className="p-mt-3 p-col-12">
              <CustomLoader loadingText="Submitting..." />
            </div>
          </Optional>
        </div>
      );
    }
  };

  const formView = () => {
    switch (currentIndex) {
      case 0:
        return createUserForm();
      case 1:
        return (
          <div>
            <CustomMessage
              closeModal={props.closeModal}
              close={true}
              message={successMessage}
              messageType="success"
            />
          </div>
        );
      default:
        break;
    }
  };

  return (
    <div>
      <Optional show={!error}>{formView()}</Optional>
      <Optional show={error}>
        <CustomToast
          title="An error occured!"
          description={error}
          type="error"
        />
      </Optional>
    </div>
  );
}
